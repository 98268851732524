import request from '@/utils/request'

export default {
    wxJSTicket(params) {
        return request({
            url: '/v1/wxJSTicket',
            method: 'get',
            params
        })
    }
}
